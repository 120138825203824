<script lang="ts" setup>
    import {computed} from "vue";

    import DText from "../atoms/DText.vue";
    import DContainer from "../atoms/DContainer.vue";
    import DIcon from "../atoms/DIcon.vue";

    import {getComponentShade} from "../utils/theme";

    import type {BaseFormProps, KeyValueType} from "@songfinch/types";

    const props = withDefaults(
        defineProps<
            BaseFormProps & {
                options: string[] | KeyValueType[];
                variant: "default" | "pill";
            }
        >(),
        {
            theme: "light",
            variant: "pill"
        },
    );

    const model = defineModel<string>();

    const formId = "form-input-" + props.id;

    const isOptionSelected = computed(() => !!model.value);

    const shade = computed(() => getComponentShade(props.theme, props.hasError));
    const wrapperClass = computed(() => props.variant === "pill" ? "pill-wrapper" : "d-input-wrapper");
    const inputClass = computed(() => props.variant === "pill" ? "pill-select" : "d-input");

    const isKeyValueType = (option: unknown): option is KeyValueType => {
        return !!(option as KeyValueType).key;
    };

    const optionValue = (option: KeyValueType | string): string => {
        return isKeyValueType(option) ? String(option.value) : option;
    };

    const optionKey = (option: KeyValueType | string): string => {
        return isKeyValueType(option) ? option.key : option;
    };
</script>

<template>
    <DContainer :class="[wrapperClass, `${shade}-shade with-icon`, 'd-select', {'option-selected': (variant === 'pill' && isOptionSelected)}]">
        <DContainer v-if="variant !== 'pill'" class="d-input-label-wrapper" :theme="theme">
            <DText
                class="d-input-label"
                type="label"
                variant="form-label"
                :for="formId"
            >
                {{label}}
            </DText>
        </DContainer>

        <select :id="formId" v-model="model" :class="inputClass" :name="formId">
            <option v-if="placeholder" value="" disabled :selected="!model">
                {{placeholder}}
            </option>
            <template v-for="option in options" :key="option">
                <option
                    v-if="optionValue(option) !== '' || model"
                    :value="optionValue(option)"
                    :disabled="isKeyValueType(option) ? option.disabled : false"
                >
                    {{optionKey(option)}}
                </option>
            </template>
        </select>

        <DIcon v-if="hasError" class="d-input-error-icon" name="alert"/>
        <DIcon class="d-input-dropdown-icon" name="dropdown"/>
    </DContainer>
</template>

<style lang="scss" scoped>
    .d-select {
        cursor: pointer;
        .icon {
            pointer-events: none;
        }
    }

    .pill-wrapper {
        position: relative;
        border-radius: 6.25rem;
        border: 1px solid var(--soft-black-tint-20);
        outline: 0;
    }

    .pill-select {
        width: 100%;
        height: 100%;
        user-select: none;
        padding: .5rem 1.125rem .5625rem;
        transition: color 0.3s ease;
        background-color: transparent;
        white-space: nowrap;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .option-selected {
        background-color: var(--soft-black);
        color: var(--white);

        option {
            color: var(--soft-black);

            &[disabled] {
                color: var(--dark-grey);
            }
        }
    }

    .d-input-dropdown-icon {
        margin-left: .5rem;
    }
</style>
