<template>
    <component
        :is="type"
        class="d-container"
        :class="`${normalizeVariant(variant)} ${theme} ${display} ${shadow ? shadow + '-shadow' : ''} ${spacing}`"
        v-bind="$attrs"
    >
        <slot/>
    </component>
</template>

<script setup lang="ts">
    import type {ComponentThemes} from "@songfinch/types";
    import {isString} from "@songfinch/utils/src/validators";

    type DContainerVariantTypes = 
        "block" | 
        "block-tight" |
        "block-bleed" | 
        "block-width-bleed" | 
        "card" | 
        "card-bleed" |
        "modal" | 
        "";

    type ContainerVariantType =
        | DContainerVariantTypes
        | DContainerVariantTypes[]
        | (string & NonNullable<unknown>);

    type DContainerPropsType = {
        type?: "section" | "footer" | "nav" | "div" | "span" | "ul" | "li" | "label";
        display?: "grid" | "grid-2" | "flex" | "";
        theme?: ComponentThemes;
        shadow?: "snackbar" | "";
        variant?: ContainerVariantType;
        spacing?: "tight" | "medium" | "large" | "xl" | "";
    };

    withDefaults(defineProps<DContainerPropsType>(), {
        type: "div",
        display: "",
        justify: "",
        theme: "",
        variant: "",
        spacing: "",
        shadow: "",
    });

    const normalizeVariant = (variant: ContainerVariantType) => {
        if (isString(variant)) return variant;
        return variant.join(" ");
    };
</script>

<style lang="scss" scoped>
ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

/*  */
/* DISPLAY */
/*  */
.grid,
.grid-2 {
  display: grid;
  align-items: flex-start;
}

.grid-2 {
  display: grid;
  
  @include md {
    grid-template-columns: repeat(2, minmax(0, calc(1200px / 2)));
  }
}

.flex {
  display: flex;
}

.block,
.block-bleed,
.block-tight {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(auto, 1440px);
  justify-content: center;
}

.grid,
.grid-2 {
  display: grid;
  align-items: flex-start;
}

.grid-2 {
  display: grid;

  @include md {
    grid-template-columns: repeat(2, minmax(0, calc(1200px / 2)));
    justify-content: center;
  }
}

.flex {
  display: flex;
}

/*  */
/* Variants */
/*  */
.block {
  padding: var(--size-24) var(--size-20) var(--size-40);

  @include md {
    padding: var(--size-64) var(--size-30) var(--size-96);
  }
}

.block-tight {
  padding: var(--size-20) var(--size-20);

  @include md {
    padding: var(--size-56) var(--size-30);
  }
}

.block-bleed {
  padding: var(--size-24) 0 var(--size-40);

  @include md {
    padding: var(--size-64) 0 var(--size-96);
  }
}

.card,
.card-bleed {
  border-radius: var(--radius-16);
}

.card {
  padding: var(--size-20);
}

.card-bleed {
  overflow: hidden;
}

.modal {
  height: 100%;
  padding: var(--size-30) var(--size-16);

  @include md {
    padding: var(--size-48) var(--size-30);
  }
}

/* THEMES */
.light {
  background-color: var(--stone);
  color: var(--deep-black);
}

.white {
  background-color: var(--white);
  color: var(--soft-black);
}

.pale {
  background-color: var(--chalk);
  color: var(--deep-black);
}

.dark {
  background-color: var(--deep-black);
  color: var(--white);
}

.shadow {
  background-color: var(--soft-black);
  color: var(--white);
}

.concrete {
  background-color: var(--dark-grey);
  color: var(--white);
}

.transparent-light {
  background-color: transparent;
  color: var(--soft-black);
}

.transparent-dark {
  background-color: transparent;
  color: var(--white);
}

/*  */
/* UTILS */
/*  */
.tight {
  padding: var(--size-20) var(--size-12);
}

.medium {
  padding: var(--size-20);
}

.large {
  padding: var(--size-48) var(--size-20);

  @include md {
    padding: var(--size-64) var(--size-48);
  }
}

.xl {
  padding: var(--size-64) var(--size-20);
}

// shadow
.snackbar-shadow {
  box-shadow: 0px 8px 16px 0px rgba(16, 16, 14, 0.3);
}
</style>
