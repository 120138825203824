<template>
    <button
        v-if="type === 'button' && !to"
        :data-test-id="`${dataTestId}-button`"
        class="d-button"
        :disabled="disabled"
        :class="`${variant} ${shade}`"
        :aria-label="ariaLabel"
    >
        <DContainer class="d-button-text" :class="`${variant} ${size} ${hasIcon}`">
            <DIcon v-if="icon" :name="icon" class="d-button-icon" :size="size"/>
            <span v-if="label">{{label}}</span>
        </DContainer>
    </button>

    <DLink
        v-else
        class="d-button"
        :data-test-id="`${dataTestId}-button-link`"
        :disabled="disabled"
        :class="`${variant} ${shade}`"
        :aria-label="ariaLabel"
        :to="to"
        :tabindex="0"
    >
        <DContainer class="d-button-text" :class="`${variant} ${size} ${hasIcon}`">
            <DIcon v-if="icon" :name="icon" class="d-button-icon"/>
            <span v-if="label">{{label}}</span>
        </DContainer>
    </DLink>
</template>

<script setup lang="ts">
    import {computed} from "vue";

    import DLink from "./DLink.vue";
    import DIcon from "./DIcon.vue";
    import DContainer from "./DContainer.vue";
    import {getComponentShade} from "../utils/theme";

    import type {
        IconNameTypes,
        ComponentThemes,
        ButtonVariants,
        ButtonSizes,
    } from "@songfinch/types";
    import type {RouteLocationRaw} from "vue-router";

    type DButtonBasePropsType = {
        dataTestId: string;
        type?: "button" | "link";
        theme?: ComponentThemes;
        variant?: ButtonVariants;
        size?: ButtonSizes;
        disabled?: boolean;
        to?: RouteLocationRaw;
        hasError?: boolean;
    };

    type DButtonPropsWithLabel = DButtonBasePropsType & {
        label: string;
        icon?: IconNameTypes;
        ariaLabel?: never;
    };

    type DButtonPropsWithIconOnly = DButtonBasePropsType & {
        label?: never;
        icon: IconNameTypes;
        ariaLabel: string;
    };

    type DButtonPropsType = DButtonPropsWithLabel | DButtonPropsWithIconOnly;

    const props = withDefaults(defineProps<DButtonPropsType>(), {
        theme: "light",
        type: "button",
        variant: "primary-cool",
        size: "medium",
    });

    const shade = computed(() => getComponentShade(props.theme, props.hasError));

    const hasIcon = computed(() => (props.icon && props.label ? "w-icon" : ""));
</script>

<style scoped lang="scss">
.d-button {
  /* base styles */
  padding: 0;
  border: 0;
  display: block;
  border-radius: var(--radius-40);
  overflow: hidden;
  box-sizing: content-box;
  cursor: pointer;

  /* Light Backgrounds */
  &.light {
    // secondary
    --button-secondary-outline-color: var(--soft-black);
    --button-secondary-outline-border: var(--soft-black-tint-20);
    --button-secondary-outline-border-active: var(--soft-black);

    --button-secondary-tonal-color: var(--deep-black);
    --button-secondary-tonal-background: var(--soft-black-tint-8);
    --button-secondary-tonal-active-tint: var(--soft-black-tint-12);

    --button-secondary-noir-color: var(--white);
    --button-secondary-noir-background: var(--soft-black);
    --button-secondary-noir-active-tint: var(--soft-black-tint-80);

    // tertiary
    --button-tertiary-color: var(--white);
    --button-tertiary-background: var(--soft-black);
    --button-tertiary-active-tint: var(--white-tint-8);

    // icon
    --icon-color: var(--deep-black);

    // disabled
    --button-disabled-background: var(--soft-black-tint-8);
    --button-disabled-color: var(--soft-black);
    --button-disabled-outline-color: var(--soft-black-tint-8);
  }

  /* Dark Backgrounds */
  &.dark {
    // secondary
    --button-secondary-outline-color: var(--white);
    --button-secondary-outline-border: var(--white-tint-20);
    --button-secondary-outline-border-active: var(--white);

    --button-secondary-tonal-color: var(--white);
    --button-secondary-tonal-background: var(--white-tint-8);
    --button-secondary-tonal-active-tint: var(--white-tint-60);

    --button-secondary-noir-color: var(--deep-black);
    --button-secondary-noir-background: var(--white);
    --button-secondary-noir-active-tint: var(--white-tint-80);

    // tertiary
    --button-tertiary-color: var(--deep-black);
    --button-tertiary-background: var(--white);
    --button-tertiary-active-tint: var(--soft-black-tint-8);

    // icon
    --icon-color: var(--white);

    // disabled
    --button-disabled-background: var(--white-tint-8);
    --button-disabled-color: var(--white);
    --button-disabled-outline-color: var(--white-tint-8);
  }

  &.error {
    --icon-color: var(--cherry);
  }

  /* variants */
  &.primary-cool {
    color: var(--white);
    background-color: var(--vivid-blue);

    &:disabled {
      cursor: initial;
      color: var(--button-disabled-color);
      background-color: var(--button-disabled-background);
    }

    &:not(:disabled):hover {
      .d-button-text {
        background-color: var(--white-tint-12);
      }
    }
  }

  &.primary-warm {
    color: var(--white);
    background-color: var(--cherry);

    &:disabled {
      cursor: initial;
      color: var(--button-disabled-color);
      background-color: var(--button-disabled-background);
    }

    &:not(:disabled):hover {
      .d-button-text {
        background-color: var(--white-tint-12);
      }
    }
  }

  &.secondary-outline {
    color: var(--button-secondary-outline-color);
    outline: 1px solid;
    outline-color: var(--button-secondary-outline-border);
    background-color: transparent;

    &:disabled {
      cursor: initial;
      color: var(--button-disabled-color);
      outline-color: var(--button-disabled-outline-color);
    }

    &:not(:disabled):hover {
      outline-color: var(--button-secondary-outline-border-active);
    }
  }

  &.secondary-tonal {
    color: var(--button-secondary-tonal-color);
    background-color: var(--button-secondary-tonal-background);

    &:disabled {
      cursor: initial;
      color: var(--button-disabled-color);
      background-color: var(--button-disabled-background);
    }

    &:not(:disabled):hover {
      background-color: var(--button-secondary-tonal-active-tint);
    }
  }

  &.secondary-noir {
    color: var(--button-secondary-noir-color);
    background-color: var(--button-secondary-noir-background);

    &:disabled {
      cursor: initial;
      color: var(--button-disabled-color);
      background-color: var(--button-disabled-background);
    }

    &:not(:disabled):hover {
      background-color: var(--button-secondary-noir-active-tint);
    }
  }

  &.tertiary {
    color: var(--button-tertiary-color);
    background-color: var(--button-tertiary-background);

    &:disabled {
      cursor: initial;
      color: var(--button-disabled-color);
      background-color: var(--button-disabled-background);
    }

    &:not(:disabled):hover {
      .d-button-text {
        background-color: var(--button-tertiary-active-tint);
      }
    }
  }

  &.circle {
    color: var(--button-tertiary-color);
    background-color: var(--button-tertiary-background);

    &:disabled {
      cursor: initial;
      color: var(--button-disabled-color);
      background-color: var(--button-disabled-background);
    }

    &:not(:disabled):hover {
      .d-button-text {
        background-color: var(--button-tertiary-active-tint);
      }
    }
  }

  &.circle-outline {
    color: var(--button-secondary-outline-color);
    outline: 1px solid var(--button-secondary-outline-border);
    background-color: transparent;

    &:disabled {
      cursor: initial;
      color: var(--button-disabled-color);
      background-color: var(--button-disabled-background);
    }

    &:not(:disabled):hover {
      outline-color: var(--button-secondary-outline-border-active);
    }
  }

  &:disabled {
    opacity: 0.38;
  }

  &.icon {
    display: flex;
    align-items: center;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }
}

.d-button-text {
  /* base positioning */
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  /* base button */
  pointer-events: none;

  /* base text */
  font-weight: 380;
  line-height: normal;

  /* sizes */
  &.large {
    padding: 17px var(--size-32) 19px var(--size-32);
    font-size: 1.25rem;

    &.w-icon {
      gap: 7px;
      padding: 17px var(--size-32) 19px 28px;

      .d-button-icon {
        font-size: 14px;
      }
    }
  }

  &.medium {
    padding: 11px 24px 13px 24px;
    font-size: 1.125rem;

    &.w-icon {
      gap: 7px;
      padding: 11px 24px 13px 20px;

      .d-button-icon {
        font-size: 1rem;
      }
    }
  }

  &.small {
    padding: var(--size-8) 18px 10px 18px;
    font-size: 1rem;
    line-height: 19px;

    &.w-icon {
      gap: 7px;
      padding: var(--size-8) 18px 10px 14px;

      .d-button-icon {
        font-size: 1rem;
      }
    }
  }

  &.circle-outline,
  &.circle {
    border-radius: var(--radius-full);
    font-size: 1.5rem;

    &.medium {
      padding: var(--size-14);
    }

    &.large {
      padding: var(--size-18);
    }
  }

  &.icon {
    padding: 0;
    background-color: transparent;
    color: var(--icon-color);
  }
}
</style>
